import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How We Should Manage Information`}</h1>
    <p>{`I love IHS Canvas announcements. They're so helpful! You can get all the latest Irvine High information straight into your inbox or Canvas homepage and don't have to scramble through Instagram posts, find posters on campus, your listen to your advisement teacher talk about it. You can get information about a club, student forum, ASB, counseling, and everything in one place! Buuuutt, sometimes it's too much! There have been weeks where my whole email inbox is flooded with Canvas notifications about upcoming events and dates. It's difficult to go through all of them and remember all of the information/look back on it.`}</p>
    <p>{`My suggestion is that we should group all the announcements for a day and send it in one big announcement. The Weekly Spur goes out to parents weekly, the bulletin is for both students and parents throughout the week, and the daily announcements are good for students to know. That way we get one email with everything in it! If there's something specifically extra important related to graduation or AP exams or counseling, that could be sent out separately, but for club information or smaller events, grouping them up would be enormously more streamlined.`}</p>
    <p>{`I think it's important for students to know when and where things are happening on campus, using different communication methods are just the way to do that! If we have an Irvine High app next year, we can set-up notifications for it, implement Remind, add it into the weekly ASB newsletter I mentioned in another article, and more.`}</p>
    <p>{`Instagram isn't enough. There are so many people who don't have social media, or just don't check it that often. We need to spread awareness and publicize all events between any student organization, but we need to do it in a streamlined and efficient manner.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      